
html,body {
    width: 100%;
    margin: 80px 0 0 0;
    padding: 0px;
    overflow-x: hidden; 
}


/*-----------------------------------------------------------Colours*/

.bg-hero,
.bg-series,
.bg-kartinginfo,
.bg-tracktitan,
.bg-about,
.bg-projects,
.bg-contact {
  background-color: #ebe9eb;
}

.bg-about {
  padding: 90px 0;
}


/*-----------------------------------------------------------Navbar*/

.banner-font a {
  text-decoration: none;
  color: #ffffff;
  font-size: 20px;
  font-family: "Inter", sans-serif;
}

.nav-menu-font {
  font-size: 20px;
  font-family: "Inter", sans-serif;
}

.nav-logo-font {
  font-size: 26px;
  font-family: "Bruno Ace", sans-serif;
}


/*-----------------------------------------------------------YouTube Button*/

.yt-button {
  background-color: #DC3545;
  color: #ffffff;
  padding: 10px 30px;
  border-radius: 10px;
  font-size: 20px;
  text-decoration: none;
  width: 200px;
  text-align: center;
}

/*-----------------------------------------------------------Track Titan Button*/

.tracktitan-button {
  width: 200px;
  background-color: #000000;
  border-radius: 10px;
  height:53px;
}


/*-----------------------------------------------------------Buy Me A Coffee Button*/

.coffee-button {
  width: 200px;
}


/*-----------------------------------------------------------Hero*/

.hero-pic {
  width: 100%;
  height: 60vh;
  object-fit: cover;
  object-position: center;
  filter: contrast(120%);
}

.hero-text {
  font-family: "Inter", sans-serif;
  font-size: 60px;
  padding: 100px 20px;
  text-transform: uppercase;
}

.banner {
  background-image: url("./assets/images/banner.png");
  width: 100%;
  height: 20vh;
  object-fit: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: 100% 100%;
  align-items: center;
}

.banner-pic {
  border-radius: 10px;
  width: 100%;
  height: 40vh;
  margin: 20px 0 20px 60px;
}

/*-----------------------------------------------------------About*/

.about-title {
  font-family: "Bruno Ace", cursive;
  font-size: 30px;
  color: 	#DC3545;
}

.about-text {
  color: #000000;
  font-family: "Inter", sans-serif;
  font-size: 20px;
}

.wwm-button {
  background-color: #8a8787;
  color: #ffffff;
  padding: 10px 30px;
  border-radius: 10px;
  font-size: 20px;
  margin: 0 10px;
  text-decoration: none;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  text-align: center;
}

.wwm-button:hover {
  background-color: #b0aeae;
}

.about-pic {
  object-fit: cover;
  border-radius: 50%;
  width: 70%;
  margin-bottom: 20px;
}

/*-----------------------------------------------------------Projects*/

.sim-racing {
  background-image: url("./assets/images/sim.jpg");
  width: 100%;
  height: 80vh;
  object-fit: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: 100% 100%;
  align-items: center;
}

.sim-text {
  color: #000000;
  font-family: "Inter", sans-serif;
  background-color: #ebe9eb;
  margin: 20px 0 20px 60px;
  border-radius: 10px;
  padding: 20px;
  height: 400px;
  font-size: 18px;
}


.editing-text,
.karting-text {
  color: #000000;
  font-family: "Inter", sans-serif;
  background-color: #ebe9eb;
  margin: 20px 0 20px 60px;
  border-radius: 10px;
  padding: 20px;
  height: 300px;
  font-size: 18px;
}

.karting-text {
  margin: 20px 0 20px 180px;
}

.karting {
  background-color: #ebe9eb;
  width: 100%;
  height: 60vh;
  background-size: 100% 100%;
  align-items: center;
}

.editing {
  background-image: url("./assets/images/editing.jpg");
  width: 100%;
  height: 60vh;
  object-fit: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: 100% 100%;
  align-items: center;
}

.editing-pic {
  border-radius: 10px;
  width: 100%;
  height: 40vh;
  margin: 20px 0 20px 60px;
}


/*-----------------------------------------------------------Contact*/


.red-banner {
  width: 100%;
  height: 5vh;
  object-fit: cover;
}

.contact-form-wrapper {
  padding-bottom: 100px;
}

.contact-form {
  padding: 30px 40px;
  background-color: #ebe9eb;
  border-radius: 12px;
  max-width: 500px;
}

.contact-form textarea {
  resize: none;
}

.contact-form .form-input {
  background-color: #ffffff;
  height: 50px;
  padding-left: 16px;
  font-family: "Inter", sans-serif;
}

.form-text-area {
  background-color: #ffffff;
  height: 300px;
  padding-left: 16px;
  font-family: "Inter", sans-serif;
}

.contact-form .form-control::placeholder {
  color: #797d81;
  font-weight: 500;
  opacity: 1;
  font-family: "Inter", sans-serif;
}

.contact-form .form-control:-ms-input-placeholder {
  color: #797d81;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.contact-form .form-control::-ms-input-placeholder {
  color: #797d81;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.contact-form .form-control:focus {
  border-color: #e66f7b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07), 0 0 8px #e66f7b;
}

.description {
  color: #797d81;
  font-size: 18px;
  text-align: center;
  font-family: "Inter", sans-serif;
}

.contact-form button {
  border: none;
  border-radius: 4px;
  background-color: #DC3545;
  color:#ffffff;
  text-transform: uppercase;
  padding: 10px 60px;
  font-weight: 500;
  letter-spacing: 2px;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  transition: 0.3s;
  width: 100%
}

.contact-form button:hover {
  background-color: #ba202f;
}


/*-----------------------------------------------------------Footer*/

.footer-text h2 {
  color: #ffffff;
  font-size: 11px;
  font-family: "Inter", sans-serif;
}

/*-----------------------------------------------------------Current Series and Karting Paths*/

.series-text,
.kartinginfo-text {
  font-family: "Inter", sans-serif;
  font-size: 18px;
}

.series-title,
.kartinginfo-title {
  font-size: 26px;
  font-family: "Bruno Ace", sans-serif;
  padding: 100px 0;
}

/*-----------------------------------------------------------Track Titan*/

.tracktitan-text {
  padding: 160px 0;
  font-size: 18px;
}

.tracktitan-title {
  padding-bottom: 80px;
}

.tracktitan-info {
  font-family: "Inter", sans-serif;
  padding: 0 10px;
}


/*-----------------------------------------------------------Media Queries*/

@media (max-width: 575px) {
  .yt-button,
  .wwm-button {
    display:block;
    font-size: 12px;
    padding: 5px 10px;
    margin: 0 3px;
    text-align: center;
  }
  .project-image {
    height: 250px;
  }
  .sim-racing {
    height: 100vh;
    align-items: center;
}
  .sim-text {
    height: 540px;
  }
  .tracktitan-text div iframe {
    width: 320px;
    height: 171px;
  }
  .about-pic {
    margin-bottom: 60px;
  }

}

@media (max-width: 1199px) {
  .sim-text,
  .editing-text,
  .karting-text  {
    margin: auto;
  }
  .tracktitan-title {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .sim-racing {
    height: 60vh;
    align-items: center;
  }

}